import React from 'react';
import {Route, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";

const AddRoute = ({component, path}) => {
    const addFormData = useSelector(({treasury}) => treasury.creditNotes.addFormData);
    const { path: currentPath } = useRouteMatch();
    if(Object.keys(addFormData).length === 0) {
        return <Redirect to={currentPath} />;
    }
    return (
        <Route path={path} component={component} />
    );
};

export default AddRoute;