import React from 'react';
import OutlinedInput from "../OutlinedInput";

const MoneyInput = ({min = 0, ...rest}) => {
    return (
        <OutlinedInput
            {...rest}
            type="number"
            inputProps={{
                min: min,
                step: 0.01,
            }}
        />
    );
};

export default MoneyInput;