import React from 'react';
import BasicDetailsForm from "./BasicDetailsForm";
import {Route, Switch, useRouteMatch} from "react-router-dom";
import GlobalDiscountForm from "./GlobalDiscountForm";
import {addPaths} from "../constants";
import DescriptionErrorForm from "./DescriptionErrorForm";
import AddRoute from "./AddRoute";
import RefundItemForm from "./RefundItemForm";
import DiscountItemForm from "./DiscountItemForm";

const AddNote = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <AddRoute path={`${path}/${addPaths.globalDiscount}`} component={GlobalDiscountForm} />
            <AddRoute path={`${path}/${addPaths.descriptionError}`} component={DescriptionErrorForm} />
            <AddRoute path={`${path}/${addPaths.refundItem}`} component={RefundItemForm} />
            <AddRoute path={`${path}/${addPaths.discountItem}`} component={DiscountItemForm} />
            <Route path={`${path}/`} component={BasicDetailsForm} />
        </Switch>

    );
};

export default AddNote;