import React from 'react';
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../hooks/Common/useForm";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {parseNumberToMoney} from "../../../../utils/parsers";
import MoneyInput from "../../../Common/Forms/MoneyInput";
import {useCreateNote} from "../../../../hooks/Treasury/creditNotes/useCreateNote";

const initData = {
    discount: 1,
    isc: 0,
}

const GlobalDiscountForm = () => {
    const {formData, setFormData, handleSubmit} = useForm(initData);
    const {handleCreateCreditNote} = useCreateNote();
    const onSubmit = (data) => {
        handleCreateCreditNote(data);
    }
    return (
        <Box
            style={{
                display: 'grid',
                rowGap: '1rem',
            }}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <MoneyInput
                label={<IntlMessages id="global.discount" />}
                value={formData.discount}
                onChange={(value) => setFormData('discount', value)}
                min={1}
            />
            <MoneyInput
                label={<IntlMessages id="isc" />}
                value={formData.isc}
                onChange={(value) => setFormData('isc', value)}
                readOnly
            />
            <MoneyInput
                label={<IntlMessages id="igv" />}
                readOnly
                value={parseNumberToMoney(formData.discount * 0.18)}
            />
            <ContainedButton
                text={<IntlMessages id="continue"/>}
                type="submit"
                color="primary"
                style={{
                    justifySelf: "end",
                }}
            />
        </Box>
    );
};

export default GlobalDiscountForm;