import React from 'react';
import {Box} from "@material-ui/core";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {parseNumberToMoney} from "../../../../utils/parsers";

const Summary = ({discount, subtotal, total}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '1rem',
                marginTop: '1rem'
            }}
        >
            <OutlinedInput
                label={<IntlMessages id='subtotal'/>}
                value={parseNumberToMoney(subtotal)}
                disabled
            />
            <OutlinedInput
                label={<IntlMessages id='discount'/>}
                value={parseNumberToMoney(discount)}
                disabled
            />
            <OutlinedInput
                label={<IntlMessages id='total'/>}
                value={parseNumberToMoney(total)}
                disabled
            />
        </Box>
    );
};

export default Summary;