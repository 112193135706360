import React from 'react';
import Select from "../../../Common/Forms/Select";
import {useFetch} from "../../../../hooks/Common/useFetch";
import {getAllCreditNoteTypes} from "../../../../services/Treasury/CreditNotesService";
import {useLocation} from "react-router-dom";
import {getAllDebitNoteTypes} from "../../../../services/Treasury/DebitNotesService";
import {useIntl} from "react-intl";

const CreditNoteTypeSelect = ({value, onChange, disabled = false}) => {
    const location = useLocation();
    const intl = useIntl();
    const isDebit = location.pathname.includes('debit-notes');
    const {data: creditNoteTypes} = useFetch(
        isDebit ? getAllDebitNoteTypes : getAllCreditNoteTypes,
        {}, [], 'creditNoteTypes');
    const handleChange = (e) => {
        const value = e.target.value;
        onChange(creditNoteTypes.find(type => type.id === value));
    }
    return (
        <Select
            label={`${intl.messages["type.of"]} ${intl.messages[`${isDebit ? 'debit' : 'credit'}.note`]}`}
            value={value?.id ?? ''}
            onChange={handleChange}
            options={creditNoteTypes}
            fullWidth
            disabled={disabled}
        />
    );
};

export default CreditNoteTypeSelect;