import React from 'react';
import {dialogsList} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../../../../redux/reducers/Treasury/creditNotes";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import Form from "./Form";

const DiscountItemDialog = () => {
    const id = dialogsList.DISCOUNT_ITEM;
    const type = useSelector(({treasury}) => treasury.creditNotes.addFormData.type);
    const dialogs = useSelector(({treasury}) => treasury.creditNotes.dialogs);
    const isOpen = dialogs.find(dialog => dialog === id);
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    return (
        <FormDialog
            id={id}
            title={type?.name}
            onClose={onClose}
            isOpen={Boolean(isOpen)}
        >
            <Form
                id={id}
                onClose={onClose}
            />
        </FormDialog>
    );
};

export default DiscountItemDialog;