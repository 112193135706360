export const dialogsList = Object.freeze({
    ADD_CREDIT_NOTE: 'ADD_CREDIT_NOTE',
    EDIT_CREDIT_NOTE: 'EDIT_CREDIT_NOTE',
    CREDIT_NOTE_DETAILS: 'CREDIT_NOTE_DETAILS',
    EDIT_ITEM_DESCRIPTION: 'EDIT_ITEM_DESCRIPTION',
    REFUND_ITEM: 'REFUND_ITEM',
    DISCOUNT_ITEM: 'ITEM_DISCOUNT',
});

export const creditNoteTypes = Object.freeze({
    total: 'Total',
    partial: 'Parcial',
    discount: 'Descuento',
});

export const BASE_ADD_PATH = 'add';

export const addPaths = Object.freeze({
    details: 'details',
    globalDiscount: 'global-discount',
    descriptionError: 'description-error',
    refundItem: 'refund-item',
    discountItem: 'discount-item',
});

export const noteSubtypes = Object.freeze({
    total: {
        id: "1",
        next: addPaths.details,
    },
    globalDiscount: {
        id: "2",
        next: addPaths.globalDiscount,
    },
    descriptionError: {
        id: "3",
        next: addPaths.descriptionError,
    },
    itemRefund: {
        id: "4",
        next: addPaths.refundItem,
    },
    itemDiscount: {
        id: "5",
        next: addPaths.discountItem,
    },
})