import axiosInstance from "../../utils/axiosInstance";
import Response from "../../domain/Response";
import {downloadOptions} from "../../components/Treasury/common/constants";
import {mapServices} from "./BillsService";

export const getAllCreditNoteTypes = async () => {
    try {
        const {data} = await axiosInstance.get(
            `/TipoNotaCreditoElectronica/sel`,
        );
        return data.map(type => ({
            id: type.nu_id_tipo_nota_credito_electronica,
            value: type.nu_id_tipo_nota_credito_electronica,
            name: type.vc_desc_tipo_nota_credito_electronica,
            noteSubtypeId: type.nu_id_funcionalidad_tipo_nc,
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getAllBillsForSelect = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/ComprobantePago/sel_ref",
        );
        return data.map((bill) => ({
            id: bill.nu_id_comprobante_pago,
            creationDate: bill.dt_fecha,
            billNumber: bill.vc_nro_documento,
            clientName: bill.vc_cliente,
            total: +bill.nu_total,
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const addCreditNote = async ({request}) => {
    try {
        console.log(request);
        const isDebitNote = window.location.pathname.includes("debit-notes");
        const {data} = await axiosInstance.post(
            `/ComprobantePago/ins_nota_${isDebitNote ? 'debito' : 'credito'}`,
            {
                nu_id_comprobante_pago_ref: request.selectedBill?.at(0),
                dt_fecha: request.date,
                [isDebitNote ? 'nu_id_tipo_nota_debito_electronica' : 'nu_id_tipo_nota_credito_electronica']: request?.type?.id,
                vc_observacion: request.reason,
                nu_descuento_global: request.discount,
                ls_detail: request?.billDetails?.map(item => ({
                    nu_id_comprobante_pago_det: item.id,
                    vc_desc_producto: item.description,
                    nu_cantidad: item.quantity,
                    nu_valor_unitario: item.unitPrice,
                    nu_porc_descuento: item.discount,
                }))
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const editCreditNote = async ({request}) => {
    try {
        const {services, taxAmount, subtotal, total} = mapServices(Object.values(request.services));
        const discountAmount = total * ((+request?.discount || 0) / 100);
        const isDebit = window.location.pathname.includes('debit-notes');
        const {data} = await axiosInstance.post(
            '/ComprobantePago/upd',
            {
                nu_id_comprobante: request.id,
                vc_nro_documento: request.creditNoteNumber,
                nu_id_cliente: request.client.id,
                dt_fecha: request.creationDate,
                nu_id_tipo_documento: request.type,
                nu_sub_total: subtotal,
                nu_impuesto: taxAmount,
                nu_descuento: (request.discount || 0),
                nu_importe_descuento: discountAmount,
                nu_total: total,
                nu_total_con_descuento: total - discountAmount,
                ls_det: services,
                vc_observacion: request.observations,
                [isDebit ? 'nu_id_tipo_nota_debito_electronica' : 'nu_id_tipo_nota_credito_electronica']: request.creditNoteType,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}
export const deleteCreditNote = async ({creditNoteId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/del',
            {
                nu_id_comprobante: creditNoteId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const sentSunatCreditNote = async ({creditNoteId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/send_fe',
            {
                nu_id_comprobante: creditNoteId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const cancelCreditNote = async ({creditNoteId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/anu_fe',
            {
                nu_id_comprobante: creditNoteId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetchCreditNoteFile = async ({creditNoteId, fileType}) => {
    try {
        const {data} = await axiosInstance.post(
            `/ComprobantePago/descargar_${fileType.toLowerCase()}`,
            {
                arr_id_comp_pago: creditNoteId,
            }
        );
        if (fileType === downloadOptions.PDF) {
            return `data:application/pdf;base64,${data?.base64}`;
        }
        if (fileType === downloadOptions.XML) {
            return `data:text/xml;base64,${data?.base64}`;
        }
        return `data:application/x-cdr;base64,${data?.base64}`;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetchBillDataById = async (billId) => {
    try {
        const {data} = await axiosInstance.post(
            '/ComprobantePago/get_det_for_nc',
            {
                nu_id_comprobante: billId
            }
        );
        return data.map(detailItem => ({
            billId: detailItem.nu_id_comprobante_pago,
            id: detailItem.nu_id_comprobante_pago_det,
            serviceId: detailItem.nu_id_servicio,
            description: detailItem.vc_desc_servicio ?? detailItem.vc_descripcion_concepto,
            quantity: detailItem.nu_cantidad,
            taxId: detailItem.nu_id_impuesto,
            taxPercentage: detailItem.nu_porc_impuesto,
            unitPrice: detailItem.nu_valor_unitario,
            salePrice: detailItem.nu_precio_venta,
            discountPercentage: detailItem.nu_porc_descuento,
            discountAmount: detailItem.nu_importe_descuento,
            amountWithDiscount: detailItem.nu_precio_con_descuento,
            netAmount: detailItem.nu_importe_neto,
            taxAmount: detailItem.nu_importe_impuesto,
            totalAmount: detailItem.nu_importe_total,
            edited: false,
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}
