import React, {useState} from 'react';
import MoneyInput from "../../../../../Common/Forms/MoneyInput";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {parseNumberToMoney} from "../../../../../../utils/parsers";
import {useCalcService} from "../../../../../../hooks/Treasury/services/useCalcService";
import {updateBillItem} from "../../../../../../redux/reducers/Treasury/creditNotes";


const Form = ({id, onClose}) => {
    const intl = useIntl();
    const item = useSelector(({treasury}) => treasury.creditNotes.selectedItem);
    const [discount, setDiscount] = useState(1);
    const dispatch = useDispatch();
    const {calcService} = useCalcService();
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        calcService({
            unitPrice: item.unitPrice,
            quantity: item.quantity,
            tax: item.taxId,
            serviceId: item.id,
            discount,
        })
            .then(({serviceResponse}) => {
                dispatch(updateBillItem({
                    ...item,
                    ...serviceResponse,
                }))
                onClose();

            });
        onClose();
    }
    return (
        <form
            id={id}
            onSubmit={onSubmit}
            className="flex flex-col gap-xs"
        >
            <OutlinedInput
                label={intl.messages["quantity"]}
                value={item.quantity}
                readOnly
                disabled
            />
            <OutlinedInput
                label={intl.messages["description"]}
                value={item.description}
                readOnly
                disabled
            />
            <MoneyInput
                label={intl.messages["unit.price"]}
                value={item.unitPrice}
                readOnly
                disabled
            />
            <MoneyInput
                label={intl.messages["discount"]}
                name="discount"
                value={discount}
                onChange={value => setDiscount(value)}
                min={1}
            />
            <OutlinedInput
                label={`${intl.messages["tax"]} (${item.taxPercentage})`}
                disabled
                value={parseNumberToMoney(parseFloat(item.taxPercentage) / 100 * (parseFloat(item.unitPrice) * parseInt(item.quantity) - parseFloat(discount || 0)))}
            />
            <OutlinedInput
                label={intl.messages["sale.import"]}
                disabled
                value={parseNumberToMoney((1 + parseFloat(item.taxPercentage) / 100) * (parseFloat(item.unitPrice) * parseInt(item.quantity) - parseFloat(discount || 0)))}
            />
        </form>
    );
};

export default Form;