import React from 'react';
import {useSelector} from "react-redux";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import {useIntl} from "react-intl";

const Details = () => {
    const intl = useIntl();
    const currentFormData = useSelector(({treasury}) => treasury.creditNotes.addFormData);
    return (
        <fieldset className="flex flex-col gap-sm padding-block-sm">
            <legend>
                {intl.messages["credit.note"]}
            </legend>
            <OutlinedInput
                label={`${intl.messages["type.of"]} ${intl.messages['credit.note']}`}
                value={currentFormData?.type?.name}
                readOnly
            />
            <OutlinedInput
                label={intl.messages["bill.number"]}
                value={currentFormData.billNumber}
                readOnly
            />
            <OutlinedInput
                label={intl.messages["reason"]}
                value={currentFormData.reason}
                readOnly
            />
        </fieldset>
    );
};

export default Details;