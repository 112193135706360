import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import MoneyInput from "../../../../../Common/Forms/MoneyInput";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {parseNumberToMoney} from "../../../../../../utils/parsers";
import {useCalcService} from "../../../../../../hooks/Treasury/services/useCalcService";
import {updateBillItem} from "../../../../../../redux/reducers/Treasury/creditNotes";

const INITIAL_DATA = {
    quantity: 0,
    unitPrice: 0,
};

const Form = ({id, onClose}) => {
    const intl = useIntl();
    const item = useSelector(({treasury}) => treasury.creditNotes.selectedItem);
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit} = useForm({
        ...INITIAL_DATA,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
    });
    const {calcService} = useCalcService();
    const onSubmit = (data) => {
        calcService({
            ...data,
            tax: item.taxId,
            serviceId: item.id,
            discount: 0,
        })
            .then(({serviceResponse}) => {
                dispatch(updateBillItem({
                    ...item,
                    ...serviceResponse,
                }))
                onClose();

            });
    }
    return (
        <form
            id={id}
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-xs"
        >
            <OutlinedInput
                label={intl.messages["quantity"]}
                name="quantity"
                value={formData.quantity}
                onChange={value => setFormData('quantity', value)}
            />
            <OutlinedInput
                label={intl.messages["description"]}
                readOnly
                value={item.description}
                disabled
            />
            <MoneyInput
                label={intl.messages["unit.price"]}
                name="unitPrice"
                value={formData.unitPrice}
                onChange={value => setFormData('unitPrice', value)}
            />
            <OutlinedInput
                label={`${intl.messages["tax"]} (${item.taxPercentage})`}
                disabled
                value={parseNumberToMoney(parseFloat(item.taxPercentage) / 100 * parseFloat(formData.unitPrice) * parseInt(formData.quantity))}
            />
            <OutlinedInput
                label={intl.messages["sale.import"]}
                disabled
                value={parseNumberToMoney((1 + parseFloat(item.taxPercentage) / 100) * parseFloat(formData.unitPrice) * parseInt(formData.quantity))}
            />
        </form>
    );
};

export default Form;