import React from 'react';
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "../constants";
import {closeDialog, setCurrentCreditNote} from "../../../../redux/reducers/Treasury/creditNotes";
import ServicesTable from "./ServicesTable";
import CreditNoteDetails from "./CreditNoteDetails";
import {Box} from "@material-ui/core";
import Summary from "./Summary";

const DetailsDialog = () => {
    const dialogs = useSelector(({treasury}) => treasury.creditNotes.dialogs);
    const currentCreditNote = useSelector(({treasury}) => treasury.creditNotes.currentCreditNote);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.CREDIT_NOTE_DETAILS);
    const handleClose = () => {
        dispatch(closeDialog(dialogsList.CREDIT_NOTE_DETAILS));
        dispatch(setCurrentCreditNote(null));
    }
    return (
        <FullScreenDialog
            open={open}
            handleClose={handleClose}
            title={currentCreditNote?.billNumber}
        >
            {currentCreditNote &&
                <Box
                    sx={{
                        margin: '1rem',
                    }}
                >
                    <CreditNoteDetails creditNote={currentCreditNote}/>
                    <ServicesTable services={currentCreditNote.services}/>
                    <Summary discount={currentCreditNote.discount} subtotal={currentCreditNote.subtotal} total={currentCreditNote.total}/>
                </Box>
            }
        </FullScreenDialog>
    );
};

export default DetailsDialog;