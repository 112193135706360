import React from 'react';
import Details from "./error/Details";
import BillInfo from "./error/BillInfo";
import {useDispatch} from "react-redux";
import {openDialog, setSelectedItem} from "../../../../redux/reducers/Treasury/creditNotes";
import {dialogsList} from "../constants";
import DescriptionErrorDialog from "./error/DescriptionErrorDialog";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useCreateNote} from "../../../../hooks/Treasury/creditNotes/useCreateNote";

const DescriptionErrorForm = () => {
    const dispatch = useDispatch();
    const {saveCreditNoteWithServices} = useCreateNote();
    const openEdit = (item) => {
        dispatch(openDialog(dialogsList.EDIT_ITEM_DESCRIPTION));
        dispatch(setSelectedItem(item));
    }
    return (
        <div
            className="grid gap-sm"
        >
            <Details />
            <BillInfo
                openEdit={openEdit}
            />
            <DescriptionErrorDialog />
            <ContainedButton
                text={<IntlMessages id="emit"/>}
                color="primary"
                className="justify-self-end"
                onClick={saveCreditNoteWithServices}
            />
        </div>
    );
};

export default DescriptionErrorForm;