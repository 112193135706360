import React from "react";
import _ from "lodash";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import Filters from "./Filters";
import ButtonsArea from "./ButtonsArea";
import {useDispatch, useSelector} from "react-redux";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import Checkbox from "../../../../Common/Forms/Checkbox";
import {LocalShipping, NoteAdd} from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {fetchAllTachographFiles} from "../../../../../redux/actions/FleetManagement/TachographFiles";
import PaginationFooter from "../../../../Common/Tables/PaginationFooter";
import useTableSort from "../../../../../hooks/Common/useTableSort";
import {parseDateToGlobalFormat} from "../../../../../utils/dates";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import {Tooltip} from "@material-ui/core";

const sourceIcons = {
  2: {
    icon: <AssignmentReturnedIcon />,
    text: <IntlMessages id="manual.download" />
  },
  3: {
    icon: <LocalShipping />,
    text: <IntlMessages id="automatic.download" />
  }
};


const FilesTable = ({setOpenZipDialog, setOpenIndividualDialog}) => {
  const [ selectAll, setSelectAll ] = React.useState(false);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);
  const dispatch = useDispatch();
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographFiles
  );
  const [ files, setFiles ] = React.useState(data);
  const onCheckRow = file => {
    const newFiles = _.clone(files);
    newFiles[file.id].checked = !file.checked;
    setFiles(newFiles);
  };
  const cloneFiles = file => {
    const newFile = _.clone(file);
    newFile.checked = selectAll;
    return newFile;
  };
  React.useEffect(
    () => {
      dispatch(fetchAllTachographFiles(filters));
    },
    [ filters ]
  );
  React.useEffect(
    () => {
      setFiles(data);
    },
    [ data ]
  );
  React.useEffect(
    () => {
      setFiles(_.mapValues(files, cloneFiles));
    },
    [ selectAll ]
  );
    const {tableData, onSort, order, orderBy} = useTableSort(Object.values(files));

    return (
    <React.Fragment>
      <Filters />
      <ButtonsArea
        selectedFiles={Object.values(files).filter(f => f.checked)}
        setOpenZipDialog={setOpenZipDialog}
        setOpenIndividualDialog={setOpenIndividualDialog}
      />
      <TableContainer
        head={<TableHeader selectAll={selectAll} setSelectAll={setSelectAll} orderBy={orderBy} order={order} onSort={onSort} />}
        footer={
          <PaginationFooter
            data={Object.values(tableData)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      >
        {(rowsPerPage > 0
          ? Object.values(tableData).slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : Object.values(tableData)).map(file => (
          <StyledTableRow key={file.id}>
            <StyledTableCell>
              <Checkbox
                checked={file.checked}
                onChange={value => onCheckRow(file)}
              />
            </StyledTableCell>
            <StyledTableCell>{parseDateToGlobalFormat(file.taskStartDate)}</StyledTableCell>
            <StyledTableCell>{parseDateToGlobalFormat(file.taskEndDate)}</StyledTableCell>
            <StyledTableCell>{parseDateToGlobalFormat(file.date)}</StyledTableCell>
            <StyledTableCell>
              <Tooltip title={sourceIcons[file.proc]?.text} arrow>
                {sourceIcons[file.proc]?.icon ?? <NoteAdd />}
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell>{file.plaque}</StyledTableCell>
            <StyledTableCell>{file.card}</StyledTableCell>
            <StyledTableCell>{file.driver}</StyledTableCell>
            <StyledTableCell>{file.fileName}</StyledTableCell>
            <StyledTableCell>
              {!file.standard ? <CheckCircleIcon /> : <CancelIcon />}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableContainer>
    </React.Fragment>
  );
};

export default FilesTable;
