import axiosInstance, {CancelToken} from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../StorageService";
import Service from "../../../domain/Logistic/Distribution/Service";
import Response from "../../../domain/Response";
import ServiceHeader from "../../../domain/Logistic/Distribution/ServiceHeader";
import {parseDateTimeToUtc, parseGlobalDateToUtc} from "../../../utils/dates";
import {operations} from "../../../components/Logistic/ServicesList/ServicesTable/EditServiceDialog/constants";

let headersId = 1;
const tableHeaders = [
  new ServiceHeader(headersId++, "Estado", "state", true),
  new ServiceHeader(headersId++, "PtoPlanificacion", "saleOrganizing", true),
  new ServiceHeader(headersId++, "Orden Servicio", "serviceOrder", true),
  new ServiceHeader(headersId++, "Tracto", "hopper", true),
  new ServiceHeader(headersId++, "Tolva", "trailer", true),
  new ServiceHeader(headersId++, "Conductor", "driver", true),
  new ServiceHeader(headersId++, "TfnoConductor", "driverPhone", true),
  new ServiceHeader(headersId++, "Destino", "destination", true),
  new ServiceHeader(
    headersId++,
    "Id Region Destino",
    "regionDestinationId",
    true
  ),
  new ServiceHeader(headersId++, "Productos", "products", true),
  new ServiceHeader(headersId++, "Id Region Origen", "regionOriginId", true),
  new ServiceHeader(headersId++, "Salida Origen", "originExit", true),
  new ServiceHeader(headersId++, "Llegada Origen", "originArrival", true),
  new ServiceHeader(headersId++, "Ventana Min SF", "etaEstimatedArrival", true),
  new ServiceHeader(
    headersId++,
    "Ventana Max SF",
    "etaEstimatedExit",
    true
  ),
  new ServiceHeader(
    headersId++,
    "ETA Llegada Destino",
    "etaDestinationArrival",
    true
  ),
  new ServiceHeader(headersId++, "Llegada Destino", "destinationArrival", true),
  new ServiceHeader(headersId++, "Salida Destino", "destinationExit", true),
  new ServiceHeader(
    headersId++,
    "ETA Retorno SF",
    "etaEstimatedArrivalOrigin",
    true
  ),
  new ServiceHeader(
    headersId++,
    "ETA Retorno Origen",
    "etaArrivalOrigin",
    true
  ),
  new ServiceHeader(
    headersId++,
    "Kilometros Estimados",
    "estimatedKilometers",
    true
  ),
  new ServiceHeader(
    headersId++,
    "Kilometros Recorridos",
    "traveledKilometers",
    true
  ),
  new ServiceHeader(headersId++, "Velocidad Media", "averageSpeed", true),
  new ServiceHeader(headersId++, "Orden", "order", true),
  new ServiceHeader(headersId++, "Fecha Activacion", "activationDate", true),
  new ServiceHeader(
    headersId++,
    "Fecha Inicio Programada",
    "serviceDate",
    true
  ),
  new ServiceHeader(headersId++, "Tiempo OT", "otTime", true),
  new ServiceHeader(
    headersId++,
    "Id Region Fin Cargadero",
    "regionOriginEndId",
    true
  ),
  new ServiceHeader(headersId++, 'Temperatura', 'temperature', true),
];

let cancelFetchServices = undefined;
let cancelFetchHeaders = undefined;

const cancelPrevFetchServices = () => {
  cancelFetchServices && cancelFetchServices();
};

const cancelPrevFetchHeaders = () => {
  cancelFetchHeaders && cancelFetchHeaders();
};

export const getAllServicesByFilters = async filters => {
  cancelPrevFetchServices();
  try {
    const {data} = await axiosInstance.post(
      "/OT/sel_ot_seguimiento",
      {
        nu_id_entidad_empresa: getCompanyEntityId(),
        dt_fecha_inicio: filters.startDate,
        dt_fecha_fin: filters.endDate,
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchServices = c))
      }
    );
    return data.map((service, index) => Service.map({id: index, ...service}));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllServicesHeaders = async () => {
  cancelPrevFetchHeaders();
  try {
    const {data} = await axiosInstance.post(
      "/Usuario_Distribucion_Filtros/sel",
      {
        nu_id_entidad: getUserId(),
        nu_id_entidad_empresa: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchHeaders = c))
      }
    );
    if (data.length > 0) {
      const headers = JSON.parse(data[0].vc_filtros) || [];
      return headers.map(header => ServiceHeader.map(header));
    } else {
      return tableHeaders;
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateServicesHeaders = async headers => {
  try {
    const {
      data
    } = await axiosInstance.post("/Usuario_Distribucion_Filtros/ins", {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      vc_filtros: JSON.stringify(headers)
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateServiceArrivalDate = async request => {
  try {
    const {data} = await axiosInstance.post("/OT/cierre_ot", {
      idServicio: request.serviceId,
      fecha: parseDateTimeToUtc(request.date)
    });
    if (data) {
      return "Servicio actualizado";
    }
    throw Error("Error en la actualizacion del servicio");
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateServiceTracking = async request => {
  try {
    const {data} = await axiosInstance.post(
        "/OT/upd_ot_seguimiento",
        {
          guid_id_ot: request.serviceId,
          vc_placa: request.plaque,
          nu_id_estado: operations[request.operation].isArrival,
          vc_cod_region: request.regionId,
          dt_fecha: parseDateTimeToUtc(request.date),
          nu_id_accion: operations[request.operation].id,
          dt_fecha_estado: parseGlobalDateToUtc(request.operationDate),
        });
    if (data) {
      return "Servicio actualizado";
    }
    throw Error("Error en la actualizacion del servicio");
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getCurrentServiceLocation = async service => {
  try {
    const {
      data
    } = await axiosInstance.post("/OT/sel_ot_vehiculo_ultima_posicion", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      matricula: service.hopper
    });
    return {
      latitude: data.latitude,
      longitude: data.longitude
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteServiceById = async serviceId => {
  try {
    const {
      data
    } = await axiosInstance.post("/OT/del_ot_seguimiento", {
      guid_id_ot: serviceId
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
