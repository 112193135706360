import {createCreditNote} from "../../../redux/thunks/Treasury/creditNotes";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

export const useCreateNote = () => {
    const history = useHistory();
    const billDetails = useSelector(({treasury}) => Object.values(treasury.creditNotes.billDetails));
    const dispatch = useDispatch();
    const handleCreateCreditNote = (data) => {
        const { pathname } = history.location;
        dispatch(createCreditNote({
            request: data,
        })).unwrap()
            .then(() => {
                history.push(pathname.replace('/add', ''));
            });
    }
    const saveCreditNoteWithServices = () => {
        handleCreateCreditNote({billDetails})
    }
    return {
        handleCreateCreditNote,
        saveCreditNoteWithServices,
    }
}