import React from "react";
import {useIntl} from "react-intl";
import {parseNumberToMoney} from "../../../../utils/parsers";
import {DataGrid} from "@mui/x-data-grid";

const ServicesTable = ({services = []}) => {
    const intl = useIntl();
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'serviceName',
            headerName: intl.messages['description'],
            width: Math.max(services.map(service => service.serviceName?.length * 10), 360)
        },
        {
            field: 'description',
            headerName: intl.messages['observations'],
            width: Math.max(services.map(service => service.description?.length * 10), 360)
        },
        {
            field: 'quantity',
            headerName: `NC ${intl.messages['quantity']}`,
            width: 150,
        },
        {
            field: 'unitPrice',
            headerName: intl.messages['unit.price'],
            valueFormatter: ({value}) => parseNumberToMoney(value),
            width: 150,
        },
        {
            field: 'discount',
            headerName: `% ${intl.messages['discount']}`,
            width: 150,
        },
        {
            field: 'taxAmount',
            headerName: intl.messages['tax'],
            width: 150,
            valueFormatter: ({row}) => {
                if(row.taxAmount) return `${row.taxAmount}`;
                const taxAmount = (row.unitPrice * row.ncQuantity) * (1 - row.discount/100) * (row.percentage / 100) ;
                return parseNumberToMoney(taxAmount);
            },
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({row}) => {
                if(row.total) return parseNumberToMoney(row.total);
                const subtotal = (row.unitPrice * row.ncQuantity) * (1 - row.discount/100) * (1 + row.percentage / 100);
                return parseNumberToMoney(subtotal);
            },
            width: 150,
        },
    ];
    return (
        <>
            <DataGrid
                columns={columns}
                rows={services}
                style={{
                    height: '40vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
            />
        </>
    );
};

export default ServicesTable;