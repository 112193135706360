import React from 'react';
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {pathnameSelector} from "../../../redux/selectors/router";

const breadcrumbs = [
    {
        label: <IntlMessages id="sidebar.daily.operation" />,
        link: "/treasury/invoicing/bills"
    },
    {label: <IntlMessages id={"treasury.bills.title"} />, isActive: true}
];

const PageContainer = ({children}) => {
    const pathname = useSelector(pathnameSelector);
    return (
        <PageContainerComponent
            heading={<IntlMessages id="treasury.credit.notes.title" />}
            description={
                pathname.includes('add') ?
                <>
                    <IntlMessages id="add" />{' '}
                    <IntlMessages id="credit.note" />
                </> :
                <IntlMessages id="treasury.credit.notes.description" />
            }
            breadcrumbs={breadcrumbs}
        >
            {children}
        </PageContainerComponent>
    );
};

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;