import React from 'react';
import Table from "../../../../components/Treasury/CreditNotes/home/Table";
import PageContainer from "../../../../components/Treasury/DebitNotes/PageContainer";

const DebitNotes = () => {
    return (
        <PageContainer>
            <Table />
        </PageContainer>
    );
};

export default DebitNotes;