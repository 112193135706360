import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ClientSelect
    from "../../../ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog/ElevatorInformation/ClientSelect";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import CreditNoteTypeSelect from "../add/CreditNoteTypeSelect";
import React from "react";
import {parseDateTimeToInput} from "../../../../utils/dates";

const CreditNoteDetails = ({creditNote}) => {

    return (
        <GridContainer
            spacing={2}
            style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem'
            }}
        >
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='client'
                    label={<IntlMessages id='client' />}
                    value={creditNote.clientName}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='creditNoteNumber'
                    label={<IntlMessages id='document.number'/>}
                    value={creditNote.billNumber}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='creationDate'
                    label={<IntlMessages id='date' />}
                    value={creditNote.creationDate}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CreditNoteTypeSelect
                    value={{id: creditNote.creditNoteType}}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='observations'
                    value={creditNote.observations}
                    label={<IntlMessages id='observations' />}
                    fullWidth
                    disabled
                />
            </Grid>
        </GridContainer>
    );
};

export default CreditNoteDetails;