import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useFetch} from "../../../../hooks/Common/useFetch";
import {parseDateToDateString} from "../../../../utils/dates";
import {parseNumberToMoney} from "../../../../utils/parsers";
import {useIntl} from "react-intl";
import FilterToolbar from "../../../Common/Tables/DataGrid/FilterToolbar";
import {Box} from "@material-ui/core";
import CreditNoteTypeSelect from "./CreditNoteTypeSelect";
import useForm from "../../../../hooks/Common/useForm";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import {getAllBillsForSelect} from "../../../../services/Treasury/CreditNotesService";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {updateAddFormData} from "../../../../redux/reducers/Treasury/creditNotes";
import {useHistory} from "react-router-dom";
import {addPaths, noteSubtypes} from "../constants";
import {createCreditNote} from "../../../../redux/thunks/Treasury/creditNotes";

const initData = {
    type: null,
    date: '',
    reason: '',
    selectedBill: [],
};

const BasicDetailsForm = () => {
    const id = "add-basic-details";
    const intl = useIntl();
    const history = useHistory();
    const {formData, setFormData, handleSubmit} = useForm(initData);
    const {data: bills} = useFetch(getAllBillsForSelect, {}, []);
    const dispatch = useDispatch();
    const billNumber = bills.find(bill => bill.id === formData.selectedBill[0])?.billNumber ?? "";
    const onSubmit = (data) => {
        if(!billNumber) {
            return;
        }
        const nextRoute = Object.values(noteSubtypes).find(noteSubtype => noteSubtype.id === data?.type?.noteSubtypeId)?.next;
        const {pathname} = history.location;
        dispatch(updateAddFormData({
            ...data,
            billNumber,
        }));
        if (nextRoute !== addPaths.details) {
            history.push(`${pathname}/${nextRoute}`);
        } else if (nextRoute === addPaths.details) {
            dispatch(createCreditNote({}))
                .unwrap()
                .then(() => {
                    history.push(pathname.replace('/add', ''));
                });
        }
    };
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'creationDate',
            headerName: intl.messages['date'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
        },
        {
            field: 'billNumber',
            headerName: intl.messages['document.number'],
            width: 200,
        },
        {
            field: 'clientName',
            headerName: intl.messages['client'],
            width: 200,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
    ];
    return (
        <Box
            style={{
                display: "grid",
                rowGap: "1rem",
            }}
        >
            <Box
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
                component="form"
                id={id}
            >
                <CreditNoteTypeSelect
                    value={formData.type}
                    onChange={(value) => setFormData('type', value)}
                />
                <OutlinedDatePicker
                    value={formData.date}
                    onChange={(value) => setFormData('date', value)}
                    label={intl.messages['date']}
                    fullWidth
                    required
                />
                <OutlinedInput
                    name='observations'
                    value={formData.reason}
                    onChange={value => setFormData('reason', value)}
                    label={<IntlMessages id='reason'/>}
                    fullWidth
                    required
                />
                <OutlinedInput
                    label={<IntlMessages id="selected.invoice"/>}
                    readOnly
                    value={billNumber}
                    hasError={!billNumber}
                />
                <DataGrid
                    columns={columns}
                    rows={bills}
                    style={{
                        minHeight: '40vh',
                    }}
                    selectionModel={formData.selectedBill}
                    onSelectionModelChange={newSelection => setFormData('selectedBill', newSelection)}
                    components={{
                        Toolbar: FilterToolbar,
                    }}
                />
            </Box>
            <ContainedButton
                text={<IntlMessages id="continue"/>}
                type="submit"
                color="primary"
                form={id}
                style={{
                    justifySelf: "end",
                }}
            />
        </Box>
    );
};

export default BasicDetailsForm;