import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getBillData} from "../../../../../redux/thunks/Treasury/creditNotes";
import {DataGrid} from "@mui/x-data-grid";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import clsx from "clsx";

const BillInfo = ({openEdit}) => {
    const dispatch = useDispatch();
    const selectedBill = useSelector(({treasury}) => treasury.creditNotes.addFormData.selectedBill);
    const billDetails = useSelector(({treasury}) => treasury.creditNotes.billDetails);
    useEffect(() => {
        dispatch(getBillData(selectedBill[0]))
    }, []);
    const columns = [
        {
            field: 'id',
            hide: true,
        },
        {
            field: 'description',
            headerName: <IntlMessages id="description"/>,
            width: 180,
            renderCell: ({value, row}) => (
                <span className={clsx(row.edited && 'text-primary')}>{value}</span>
            ),
        },
        {
            field: 'quantity',
            headerName: <IntlMessages id="quantity"/>,
            width: 180,
        },
        {
            field: 'unitPrice',
            headerName: <IntlMessages id="unit.price"/>,
            width: 180,
        },
        {
            field: 'actions',
            headerName: <IntlMessages id="actions"/>,
            type: 'actions',
            width: 180,
            renderCell: ({row}) => {
                return (
                    <ContainedButton
                        onClick={() => openEdit(row)}
                        text={<IntlMessages id="edit"/>}
                        color="primary"
                    />
                );
            }
        }
    ];
    return (
        <fieldset>
            <legend>
                <IntlMessages id="details"/>
            </legend>
            <DataGrid
                style={{
                    minHeight: '40vh',
                }}
                rows={Object.values(billDetails)}
                columns={columns}
            />
        </fieldset>
    );
};

export default BillInfo;