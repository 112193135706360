import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {updateBillItem} from "../../../../../../redux/reducers/Treasury/creditNotes";

const DescriptionFixForm = ({id, onClose}) => {
    const item = useSelector(({treasury}) => treasury.creditNotes.selectedItem);
    const dispatch = useDispatch();
    const onSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const description = formData.get('description');
        dispatch(updateBillItem({id: item.id, description}));
        onClose();
    }
    return (
        <form
            id={id}
            className="flex flex-col gap-sm"
            onSubmit={onSubmit}
        >
            <OutlinedInput
                label={<IntlMessages id="quantity"/>}
                value={item.quantity}
                readOnly
                disabled
            />
            <OutlinedInput
                label={<IntlMessages id="old.description"/>}
                value={item.description}
                readOnly
                disabled
            />
            <OutlinedInput
                label={<IntlMessages id="new.description"/>}
                name="description"
                required
            />
        </form>
    );
};

export default DescriptionFixForm;