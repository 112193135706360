import React from 'react';
import PageContainer from "../../../../components/Treasury/CreditNotes/PageContainer";
import Table from "../../../../components/Treasury/CreditNotes/home/Table";
import AddNote from "../../../../components/Treasury/CreditNotes/add/AddNote";
import {Route, Switch} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {BASE_ADD_PATH} from "../../../../components/Treasury/CreditNotes/constants";

const CreditNotes = () => {
    const { path } = useRouteMatch();
    return (
        <PageContainer>
            <Switch>
                <Route path={`${path}/${BASE_ADD_PATH}`} component={AddNote} />
                <Route path={path} component={Table} />
            </Switch>
        </PageContainer>
    );
};

export default CreditNotes;