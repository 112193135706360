import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {
    fetchAllCreditNotes,
    getAndSetCreditNoteDetails,
    getBillData,
    removeCreditNote
} from "../../thunks/Treasury/creditNotes";

const INIT_STATE = {
    creditNotes: {},
    dialogs: [],
    currentCreditNote: null,
    addFormData: {},
    billDetails: [],
    selectedItem: null,
}

const billSlice = createSlice({
    name: 'treasury/creditNotes',
    initialState: cloneDeep(INIT_STATE),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentCreditNote: (state, action) => {
            state.currentCreditNote = action.payload;
        },
        updateAddFormData: (state, action) => {
            state.addFormData = {...state.addFormData, ...action.payload};
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = action.payload;
        },
        updateBillItem: (state, action) => {
          state.billDetails[action.payload.id] = {...state.billDetails[action.payload.id], ...action.payload, edited: true};
        },
        resetCreditNotesState: () => cloneDeep(INIT_STATE)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreditNotes.fulfilled, (state, action) => {
                state.creditNotes = action.payload.creditNotes.reduce((obj, creditNote) => {
                    obj[creditNote.id] = creditNote;
                    return obj;
                }, {});
            })
            .addCase(removeCreditNote.fulfilled, (state, action) => {
                const {creditNoteId} = action.payload;
                const creditNotes = cloneDeep(state.creditNotes);
                delete creditNotes[creditNoteId];
                state.creditNotes = creditNotes;
            })
            .addCase(getAndSetCreditNoteDetails.fulfilled, (state, action) => {
                state.currentCreditNote = action.payload;
            })
            .addCase(getBillData.fulfilled, (state, action) => {
                const items = {};
                action.payload.forEach(item => {
                    items[item.id] = item;
                });
                state.billDetails = items;
            });
    }
});

export default billSlice.reducer;
export const {
    openDialog,
    closeDialog,
    setCurrentCreditNote,
    updateAddFormData,
    setSelectedItem,
    resetCreditNotesState,
    updateBillItem,
} = billSlice.actions;